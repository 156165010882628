<template>
  <div id="data-devices">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="Devices">
      <template v-slot:right-end>
          <div class="vx-row my-5 mx-3" v-if="activeUserInfo.account_role == 0">
            <div class="vx-col">

              <vs-button @click="openAddDevice" type="filled" icon-pack="feather"
                         icon="icon-plus">{{ $t('AddDevice') }}
              </vs-button>
            </div>
          </div>
      </template>
    </breadcrumb-base>
    <transition name="fade">
      <div class="vx-row w-full">
        <div class="w-2/5">
          <div class="text-center">
            <h2 class="custom-table-header">{{$t('UnAssignedDevices')}}</h2>
          </div>
          <!-- Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="unAssignedDevices.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-if="unAssignedDevices.length > 0 && !errorFetching" ref="table"
                             multiple
                             v-model="selectedDevices"
                             :data="unAssignedDevices">

            <template slot="header">
            </template>

            <template slot="thead">
              <vs-th >{{ $t('DeviceSerialNo') }}</vs-th>
              <vs-th >{{ $t('DeviceLastUpdated') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.device_serial_no }}</p>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.updated | dateFormat }}</p>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
          </vs-table-modified>
        </div>
        <div class="w-1/5 flex justify-center">
          <div class="vs-col">
            <div class="mt-10">
              <dynamic-customers-drop-down
                :placeholder="$t('SelectCustomer')"
                @on-item-selected="onCustomerChanged"
                :selected-item="selectedCustomer"></dynamic-customers-drop-down>
            </div>
            <div class="mt-10">
              <vs-button class="w-full" @click="assignDevicesToCustomer">{{ $t('Assign') }} {{selectedDevices.length}}</vs-button>
            </div>
            <div class="mt-10">
              <vs-button class="w-full" color="danger" @click="deleteAssignDevicesToCustomer">
                {{ $t('UnAssign') }} {{selectedAssignedDevices.length}}
              </vs-button>
            </div>
          </div>
        </div>
        <div class="w-2/5">
          <!-- Empty List State -->
          <div class="text-center">
            <h2 class="custom-table-header">{{$t('AssignedDevices')}}</h2>
          </div>
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="assignedDevices.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="assignedDevices.length > 0 && !errorFetching" ref="table"
                             multiple
                             v-model="selectedAssignedDevices"
                             :data="assignedDevices">

            <template slot="header">
            </template>
            <template slot="thead">
              <vs-th >{{ $t('DeviceSerialNo') }}</vs-th>
              <vs-th >{{ $t('UserId') }}</vs-th>
              <vs-th >{{ $t('DeviceLastUpdated') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.device_serial_no }}</p>
                </vs-td>
                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.updated | dateFormat }}</p>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'

export default {
  name: 'CustomerDevices',
  components: { DynamicCustomersDropDown },
  data() {
    return {
      customer: null,
      selectedCustomer: {},
      assignedDevices: [],
      selectedDevices: [],
      selectedAssignedDevices: [],
      unAssignedDevices: [],
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Customer', i18n: 'Customer', url: '/customers/view' },
        { title: 'Devices', i18n: 'Devices', active: true },
      ],
    }
  },
  props: {
    customerId: {
      type: String | Number,
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    openAddDevice() {
      this.$router.push({ name: 'device-add' })
    },
    onCustomerChanged(c) {
      this.selectedCustomer = c

      this.$router.push({ name: 'customer-devices', params: { customerId: this.selectedCustomer.customer_id } })
      this.fetchCustomerDevices()
    },
    deleteAssignDevicesToCustomer() {
      const payload = {}

      if (this.selectedAssignedDevices.length === 0) {
        this.notifyError('Please select Assigned devices')
        return
      }

      const j = []
      this.selectedAssignedDevices.forEach((d) => {
        j.push({ 'assigned_device_id': d.id })
      })

      payload.delete_device_list = JSON.stringify(j)
      payload.delete = '1'

      this.$vs.loading()
      return this.$store.dispatch('devices/deleteAssignedDevices', { payload })
        .then((data) => {
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: 'Devices Removed',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.$vs.loading.close()
          this.selectedAssignedDevices = []
          this.fetchCustomerDevices()
        })
        .catch((error) => {
          this.errorFetching = true
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.notifyError(msg)
        })
    },
    assignDevicesToCustomer() {
      const payload = {}
      if (Object.keys(this.selectedCustomer).length > 0) {
        payload.customer_id = this.selectedCustomer.customer_id
      } else {
        this.notifyError('Invalid Customer')
        return
      }

      if (this.selectedDevices.length === 0) {
        this.notifyError('Please select devices')
        return
      }

      const j = []
      this.selectedDevices.forEach((d) => {
        j.push({ 'serial_no': d.device_serial_no, 'status': 1 })
      })

      payload.device_data = JSON.stringify(j)

      this.$vs.loading()
      return this.$store.dispatch('devices/assignDevicesCustomer', { payload })
        .then((data) => {
          this.assignedDevices = data.assigned_devices_list
          this.unAssignedDevices = data.unassigned_devices_list
          this.$vs.loading.close()
          this.selectedDevices = []
        })
        .catch((error) => {
          this.errorFetching = true
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchCustomerDevices() {
      const payload = {}
      if (this.selectedCustomer != null) {
        payload.customer_id = this.selectedCustomer.customer_id
      } else {
        return
      }

      payload.view_type = '1'

      this.$vs.loading()
      return this.$store.dispatch('devices/fetchCustomerDevices', { payload })
        .then((data) => {
          this.assignedDevices = data.assigned_devices_list
          this.unAssignedDevices = data.unassigned_devices_list
          this.$vs.loading.close()
        })
        .catch((error) => {
          this.errorFetching = true
          this.$vs.loading.close()
          let msg = ''
          try {
            try {
              msg = error.data.message
            } catch (e) {
              msg = error.response.data.message
            }
          } catch (e) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    notifyError(msg) {
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: msg,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
  },
  mounted() {
    if(this.customerId) {
      this.customer = {
        'customer_id': this.customerId,
      }

      this.selectedCustomer = this.customer
      this.fetchCustomerDevices()
    }
  },
}
</script>

<style lang="scss">
#data-devices {

  .custom-table-header {
    font-size: 20px;
    font-weight: 600;
  }

  .vs-table--thead .td-check .con-vs-checkbox {
    display: none !important;
  }
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .suppliers-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .supplier-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
